import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Post from './post'

const Markdown = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000) 
      {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "Do MMMM YYYY")
              excerpt
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const posts = allMarkdownRemark.edges.map(edge => ({
    slug: edge.node.fields.slug,
    title: edge.node.frontmatter.title,
    date: edge.node.frontmatter.date,
    excerpt: edge.node.frontmatter.excerpt,
    source: 'remark'
  }))

  return (
    <React.Fragment>
      {posts.map((post, idx) => <Post key={idx} post={post} />)}
    </React.Fragment>
  )
}

export default Markdown