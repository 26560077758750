import * as React from 'react'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Contentful from '../components/contentful'
import * as blogStyles from "../styles/blog.module.scss"

const BlogPage = () => {
  return (
    <Layout page='Blog'>
      <h1>Blog</h1>
      <ol className={blogStyles.posts}>
        {/* <Contentful /> */}
        <Markdown />
      </ol>
    </Layout>
  )
}

export default BlogPage