import React from 'react'
import { Link } from 'gatsby'
import * as postStyles from './post.module.scss'

const Post = ({ post }) => {
  const { title, date, slug, excerpt } = post
  const link = `/blog/${slug}`

  return (
    <li className={postStyles.post}>
      <Link to={link} className={postStyles.link}>
        <h2 className={postStyles.title}>{title}</h2>
        <p className={postStyles.excerpt}>{excerpt}</p>
        <p className={postStyles.date}>{date}</p>
      </Link>
    </li>
  )
}

export default Post